import React, { useState } from "react";
import SignInIllustration from "../images/signInIllustration.png";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export const SignIn = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loading, setLoading] = useState(false);
	// const [error, setError] = useState(null);
	const navigate = useNavigate();

	const signIn = async () => {
		if (email && password) {
			setLoading(true);
			// setError(null);

			try {
				const response = await axios.post(
					"https://api.vidit.uk/v1/auth/login-email",
					{
						email,
						password,
					}
				);
				localStorage.setItem(
					"accessToken",
					response.data.data.accessToken
				);
				localStorage.setItem(
					"user",
					JSON.stringify(response.data.data)
				);
				navigate("/");
				console.log("Signed in successfully:", response.data);
			} catch (err) {
				// setError(
				// 	err.response
				// 		? err.response.data.message
				// 		: "Error signing in"
				// );
				console.error("Error signing in:", err);
			} finally {
				setLoading(false);
			}
		}
	};

	return (
		<div>
			{loading ? (
				<div>Loading...</div>
			) : (
				<div className="flex justify-center items-center md:mt-16">
					<div className="flex justify-center items-center p-16 bg-white rounded-2xl shadow gap-8 my-12 mx-36 md:my-0 md:mx-0 md:w-4/5">
						<div className="grow shrink basis-0 justify-start items-center hidden md:flex">
							<img
								className="w-96 h-96 justify-start items-center flex"
								alt="sign in illustration"
								src={SignInIllustration}
							/>
						</div>
						<div className="grow shrink basis-0 flex-col justify-start items-start gap-6 inline-flex">
							<div className="md:w-96 text-gray-900 text-3xl font-bold leading-10 flex items-start">
								Sign In
							</div>
							<div className="self-stretch h-20 flex-col justify-start items-center flex">
								<div className="self-stretch grow shrink basis-0 text-gray-900 text-base font-medium leading-normal flex items-start">
									Your email
								</div>
								<div className="self-stretch h-11 px-5 py-3 bg-gray-50 rounded-2xl border border-gray-200 justify-start items-start gap-2.5 inline-flex">
									<div className="grow shrink basis-0 self-stretch justify-start items-start flex">
										<input
											className="grow shrink outline-none basis-0 bg-transparent self-stretch text-gray-500 text-base font-normal leading-normal"
											type="email"
											placeholder="Enter email"
											onChange={(e) =>
												setEmail(e.target.value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="self-stretch h-20 flex-col justify-start items-center flex">
								<div className="self-stretch grow shrink basis-0 text-gray-900 text-base font-medium leading-normal flex items-start">
									Password
								</div>
								<div className="self-stretch h-11 px-5 py-3 bg-gray-50 rounded-2xl border border-gray-200 justify-start items-start gap-2.5 inline-flex">
									<div className="grow shrink basis-0 self-stretch justify-start items-start flex">
										<input
											className="grow shrink basis-0 self-stretch text-gray-500 outline-none bg-transparent text-base font-normal leading-normal"
											type="password"
											placeholder="Enter password"
											onChange={(e) =>
												setPassword(e.target.value)
											}
										/>
									</div>
								</div>
							</div>
							<div className="flex items-center justify-between flex-col md:flex-row gap-4 md:gap-64">
								<div className="items-start gap-2 flex">
									<input
										type="checkbox"
										className="rounded border border-gray-300 justify-end items-start gap-2 flex"
									/>
									<div className="text-gray-500 text-xs font-normal leading-none">
										Remember me
									</div>
								</div>
								<div className="text-zinc-800 text-xs font-medium leading-none">
									Forgot Password?
								</div>
							</div>
							<button
								onClick={signIn}
								className="text-white text-base font-semibold leading-normal self-stretch px-6 py-3 bg-blue-600 rounded-xl justify-center items-center inline-flex"
							>
								Sign In
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};
