// utils/auth.js

function parseJwt (token) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));

	return JSON.parse(jsonPayload);
}

export const isAuthenticated = () => {
	const token = localStorage.getItem("accessToken");
	if (!token) return false;
	
	const isValid = parseJwt(token);
	console.log('isValid', isValid)
	
	if(!isValid) return false;
	// Optional: You can add more logic to check if the token is expired
	// For example, if your token is a JWT, you can decode and check its expiry date

	return true;
};
