import React, { useState } from "react";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { IoIosArrowDown } from "react-icons/io";

const CustomDatePicker = ({ filters, handleFilterChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePicker = () => setIsOpen(!isOpen);

  console.log("Current filters.dateRange:", filters.dateRange); // Debug log

  const handleDateChange = (ranges) => {
    console.log("Updated Range:", ranges.selection); // Debug to confirm data structure
    // Assuming handleFilterChange expects a date range object similar to the state's structure
    handleFilterChange("dateRange", [ranges.selection]);
  };

  return (
    <div className="relative">
      <button
        onClick={togglePicker}
        className="border-none outline-none rounded-2xl p-4 w-60 bg-[#E4E4E4] text-[#808191] font-semibold text-sm"
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-row gap-2">
            {filters.dateRange[0].startDate && filters.dateRange[0].endDate
              ? `${filters.dateRange[0].startDate.toLocaleDateString()} - ${filters.dateRange[0].endDate.toLocaleDateString()}`
              : "Select Date Range"}
          </div>
          <IoIosArrowDown size={18} />
        </div>
      </button>
      {isOpen && (
        <div className="absolute mt-2 bg-white border rounded-2xl shadow-lg z-10 p-2 w-full">
          <DateRangePicker
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            months={1}
            direction="horizontal"
            ranges={filters.dateRange}
            onChange={handleDateChange}
          />
        </div>
      )}
    </div>
  );
};

export default CustomDatePicker;
