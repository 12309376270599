import React from "react";

const MediaTable = ({ media }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white border-gray-200">
        <thead>
          <tr>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Media
            </th>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Type
            </th>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Category
            </th>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Tag
            </th>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Location
            </th>
            <th className="px-4 py-2 border-b text-[#B2B3BD] font-medium text-[13px] pb-6">
              Date
            </th>
          </tr>
        </thead>
        <tbody>
          {media.map((item) => (
            <tr key={item.id}>
              <td className="px-4 py-6 border-b flex justify-center items-center">
                <img
                  src={item.url}
                  alt={item.name}
                  className="w-12 h-12 object-cover rounded"
                />
              </td>
              <td className="px-4 py-6 border-b text-sm">{item.type}</td>
              <td className="px-4 py-6 border-b">
                {item.mediaCategory
                  .map((item) => item.categoryMaster?.name)
                  .join(", ") || "-"}
              </td>
              <td className="px-4 py-6 border-b text-sm">
                {item.tags.join(", ") || "-"}
              </td>
              <td className="px-4 py-6 border-b text-sm">
                {item.location || "-"}
              </td>
              <td className="px-4 py-6 border-b text-sm">{item.date}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default MediaTable;
