import React, { useState, useEffect } from "react";
import illustration from "../images/signInIllustration.png";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import TagLogo from "../images/tag.png";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import UploadDropdown from "./UploadDropdown";
import CategoryLogo from "../images/category.png";
import { Chips } from "primereact/chips";
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"; // theme
// import "primereact/resources/primereact.min.css"; // core css
import "tailwindcss/tailwind.css"; // Tailwind
import "./UploadPopup.css";

const UploadPopup = ({ isVisible, onClose }) => {
  const [logo, setLogo] = useState({});
  const [userIcon, setUserIcon] = useState("");
  const [masterCategories, setMasterCategories] = useState([]);
  const [mediaUpload, setMediaUpload] = useState({
    mediaCategory: { mediaCategoryIds: [] },
    tags: [],
    date: "",
    type: "",
    description: "",
  });
  const navigate = useNavigate();

  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const getMasterData = async () => {
      try {
        const response = await axios.get(
          "https://api.vidit.uk/v1/master-data",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setMasterCategories(response.data.data.mediaCategoryIds);
      } catch (error) {
        if (error.response.data.message === "Invalid token") {
          navigate("/sign-in");
        }
      }
    };

    getMasterData();
  }, [token, navigate]);

  const handleFilterChange = (filterName, value) => {
    if (Array.isArray(value)) {
      setMediaUpload((prev) => ({
        ...prev,
        [filterName]: value,
      }));
    } else {
      setMediaUpload((prev) => ({
        ...prev,
        [filterName]: value[0], // Since single-select dropdowns now pass an array, take the first element.
      }));
    }
  };

  const handleDateChange = (e) => {
    setMediaUpload((prev) => ({
      ...prev,
      date: e.target.value,
    }));
  };

  const handleTypeChange = (value) => {
    setMediaUpload((prev) => ({
      ...prev,
      type: value[0],
    }));
  };

  const handleDescriptionChange = (e) => {
    setMediaUpload((prev) => ({
      ...prev,
      description: e.target.value,
    }));
  };

  const convertbase64 = (file) => {
    return new Promise((resolve, err) => {
      const fileReader = new FileReader();
      fileReader.onload = (eve) => {
        resolve(fileReader.result);
      };
      fileReader.readAsDataURL(file);
    });
  };

  console.log("mediaUpload", mediaUpload);

  function convertDataURIToBinary(dataURI) {
    var BASE64_MARKER = ";base64,";
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    for (var i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }
    return array;
  }

  const uploadLogo = async (e) => {
    if (e.target.files[0]) {
      setLogo({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
    }
    var file = e.target.files[0];
    const file_binary = await convertbase64(file);
    const res = await axios.get(
      `https://api.vidit.uk/v1/aws/upload-media?contentType=${file.type}`
    );
    setUserIcon(res.data.getUrl);
    var config = {
      method: "put",
      url: res.data.postUrl,
      data: convertDataURIToBinary(file_binary),
    };
    axios(config)
      .then(function (response) {
        toast.success("Logo Uploaded Successfully");
      })
      .catch(function (error) {
        toast.error(error.message);
      });
  };

  const handleCategoryChange = (selectedCategoryNames) => {
    const categoryIds = selectedCategoryNames
      .map(
        (name) =>
          masterCategories.find((category) => category.name === name)?.id
      )
      .filter(Boolean); // Filter out undefined to ensure all names had a corresponding ID

    setMediaUpload((prev) => ({
      ...prev,
      mediaCategory: { mediaCategoryIds: categoryIds },
    }));
  };

  // In the component where `UploadDropdown` is used, map `mediaUpload.categories` to their corresponding names:
  const categoryNames = mediaUpload.mediaCategory.mediaCategoryIds
    .map(
      (categoryId) =>
        masterCategories.find((category) => category.id === categoryId)?.name
    )
    .filter(Boolean); // Ensure all IDs have a corresponding name

  const createMedia = async () => {
    try {
      await axios.post(
        "https://api.vidit.uk/v1/admin/media/create",
        {
          items: [{ ...mediaUpload, url: userIcon }],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Media created successfully");
      window.location.reload();
    } catch (error) {
      toast.error(error.message);
    }
  };

  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-75 flex justify-center items-center z-50">
      <div className="bg-white rounded-lg p-6 w-full max-w-lg mx-auto shadow-lg relative">
        <button
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800 p-2 bg-white shadow rounded-full"
          onClick={onClose}
        >
          &times;
        </button>
        <h2 className="text-lg text-[#11142D] text-left font-semibold mb-4">
          Add Media
        </h2>
        <div className="flex items-center mb-4 border-b-2 border-[#E4E4E4]">
          <div className="flex flex-col">
            <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
              Image
            </label>
            <div className="flex flex-row items-center pb-4">
              <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-200 flex justify-center items-center">
                {/* Placeholder for image */}
                <img
                  src={logo.src || illustration}
                  alt={logo.alt || "Media Image"}
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="ml-4">
                <label className="bg-[#6C5DD3] text-white px-8 py-3 rounded-2xl mr-2 cursor-pointer">
                  Upload New
                  <input type="file" className="hidden" onChange={uploadLogo} />
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="mb-4">
          <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
            Category
          </label>
          <UploadDropdown
            name={"Select Categories"}
            optionHeight={"h-64"}
            disabled={false}
            categoryLogo={CategoryLogo}
            options={masterCategories.map((category) => category.name)}
            selectedOptions={categoryNames} // Pass category names here
            onSelect={handleCategoryChange}
            multiSelect={true}
          />
        </div>
        <div className="mb-4">
          <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
            Tags
          </label>
          <div className="bg-[#E4E4E4] flex flex-row p-2 rounded-md w-full">
            {/* <div className="flex-1"> */}
            <img className="p-2 w-10 h-10" src={TagLogo} alt="tag logo" />
            <Chips
              value={mediaUpload.tags}
              onChange={(e) => handleFilterChange("tags", e.value)}
              className="custom-chip-input flex-1 w-full" // Add your custom class here
              inputClassName="p-2 bg-transparent focus:outline-none w-full"
              placeholder="Input tags"
              // removeIcon="pi pi-times"
              addOnBlur
              removable
            />
          </div>
        </div>
        <div className="flex justify-between flex-row gap-2">
          <div className="mb-4 w-full">
            <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
              Type
            </label>
            <UploadDropdown
              name={"Select Type"}
              optionHeight={null}
              disabled={false}
              categoryLogo={CategoryLogo}
              options={["video", "image"]}
              selectedOptions={mediaUpload.type}
              onSelect={handleTypeChange}
              multiSelect={false}
            />
          </div>
          <div className="mb-4 w-full">
            <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
              Date
            </label>
            <input
              type="date"
              value={mediaUpload.date}
              className="border-none outline-none rounded-lg p-4 w-full bg-[#E4E4E4] text-[#11142D] font-semibold text-sm text-left"
              onChange={handleDateChange}
            />
          </div>
        </div>
        <div className="mb-4 w-full">
          <label className="block text-[#B2B3BD] font-medium text-left text-xs mb-2">
            Description
          </label>
          <textarea
            value={mediaUpload.description}
            onChange={handleDescriptionChange}
            className="border-none outline-none rounded-lg p-4 w-full bg-[#E4E4E4] text-[#11142D] font-semibold text-sm text-left h-24"
          ></textarea>
        </div>
        <div className="flex justify-end">
          <button
            onClick={createMedia}
            className="bg-black text-white px-6 py-2 rounded"
          >
            Save
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UploadPopup;
