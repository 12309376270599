import "./App.css";
import MediaList from "./pages/MediaList";
import { SignIn } from "./pages/Signin";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute.js";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/sign-in" element={<SignIn />} />
          <Route
            path="/"
            element={<PrivateRoute element={MediaList} />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
