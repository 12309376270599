import React, { useState, useEffect } from "react";
import Search from "../images/Search.png";
import Dropdown from "../components/Dropdown";
import axios from "axios";
import MediaTable from "../components/MediaTable";
import Pagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";
import { useNavigate } from "react-router-dom";
import CustomDatePicker from "../components/CustomDatePicker";
import { format } from "date-fns";
import UploadPopup from "../components/UploadPopup";

const MediaList = () => {
  const [media, setMedia] = useState([]);
  const [isPopupVisible, setPopupVisible] = useState(false);
  // Initial state setup for multi-selects should be an array.
  const [filters, setFilters] = useState({
    dateRange: [
      {
        startDate: null,
        endDate: null,
        key: "selection",
      },
    ],
    categories: [],
    types: [],
    tag: [],
  });
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [masterCategories, setMasterCategories] = useState([]);
  const [masterTags, setMasterTags] = useState([]);
  const itemsPerPage = 10;
  const token = localStorage.getItem("accessToken");

  useEffect(() => {
    const getMasterData = async () => {
      try {
        const response = await axios.get(
          "https://api.vidit.uk/v1/master-data",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setMasterCategories(response.data.data.mediaCategoryIds);
        setMasterTags(response.data.data.uniqueTags);
      } catch (error) {
        if (error.response.data.message === "Invalid token") {
          navigate("/sign-in");
        }
      }
    };

    getMasterData();
  }, [token, navigate]);

  useEffect(() => {
    // Fetch media data
    const fetchMedia = async () => {
      try {
        const mediaFilters = {};

        mediaFilters.limit = itemsPerPage;
        mediaFilters.offset = currentPage * itemsPerPage;
        if (filters.categories.length) {
          mediaFilters.categoryIds = filters.categories;
        }
        if (filters.tag.length) {
          mediaFilters.tags = filters.tag;
        }
        if (filters.types.length) {
          mediaFilters.types = filters.types;
        }
        if (filters.dateRange[0].startDate && filters.dateRange[0].endDate) {
          mediaFilters.dateFilter = {
            startDate: format(filters.dateRange[0].startDate, "yyyy-MM-dd"),
            endDate: format(filters.dateRange[0].endDate, "yyyy-MM-dd"),
          };
        }
        const response = await axios.post(
          "https://api.vidit.uk/v1/admin/media/get",
          mediaFilters,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setMedia(response.data.data.mediaDetails);
        setTotalItems(response.data.data.totalCount);
      } catch (error) {
        console.log("errorRes", error);
        if (error.response?.data.message === "Invalid token") {
          navigate("/sign-in");
        }
      }
    };
    fetchMedia();
  }, [currentPage, token, filters, navigate]);

  const handleOpenPopup = () => {
    setPopupVisible(true);
  };

  const handleClosePopup = () => {
    setPopupVisible(false);
  };

  const handleFilterChange = (filterName, value) => {
    if (Array.isArray(value)) {
      setFilters((prev) => ({
        ...prev,
        [filterName]: value,
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [filterName]: value[0], // Since single-select dropdowns now pass an array, take the first element.
      }));
    }
    // Reset to first page on filter change
    setCurrentPage(0);
  };

  const clearFilters = (e) => {
    setFilters({
      dateRange: [
        {
          startDate: null,
          endDate: null,
          key: "selection",
        },
      ],
      categories: [],
      types: [],
      tag: [],
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const logout = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("user");
    navigate("/sign-in");
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  return (
    <div className="bg-white flex flex-col w-full relative">
      {/* Media header and search */}
      <div className="flex flex-row justify-between w-full px-10 pt-10">
        <div className="[font-family:'Artifika-Regular',Helvetica] font-normal text-black text-[36px] tracking-[-0.72px] leading-[normal] whitespace-nowrap">
          Media Archive
        </div>
        <div className="gap-4 flex flex-row">
          <div className="gap-[16px] relative w-[316px] h-[64px] rounded-[16px] bg-[#E3E6EC] flex items-center pl-4">
            <img
              className="relative w-[24px] h-[24px]"
              alt="Iconly light outline"
              src={Search}
            />
            <input
              placeholder="Search"
              className="relative w-fit bg-transparent outline-none font-menu-14 font-[number:var(--menu-14-font-weight)] text-colordark-grey-neutraldeactive text-[length:var(--menu-14-font-size)] tracking-[var(--menu-14-letter-spacing)] leading-[var(--menu-14-line-height)] whitespace-nowrap [font-style:var(--menu-14-font-style)]"
            />
          </div>
          <button
            className="bg-red-500 text-white px-8 py-2 rounded-[16px]"
            onClick={logout}
          >
            Logout
          </button>
        </div>
      </div>
      <div className="flex justify-between mb-6 px-10 pt-10">
        <CustomDatePicker
          filters={filters}
          handleFilterChange={handleFilterChange}
        />
        <Dropdown
          name={"Select Categories"}
          options={masterCategories.map((category) => category.name)}
          selectedOptions={filters.categories}
          onSelect={(value) => handleFilterChange("categories", value)}
          multiSelect={true}
        />
        <Dropdown
          name={"Select Types"}
          options={["image", "video"]}
          selectedOptions={filters.types}
          onSelect={(value) => handleFilterChange("types", value)}
          multiSelect={true}
        />
        <Dropdown
          name={"Select Tags"}
          options={masterTags || ["Tag 1", "Tag 2", "Tag 3"]}
          selectedOptions={filters.tag}
          onSelect={(value) => handleFilterChange("tag", value)}
          multiSelect={true}
        />
        <button
          onClick={clearFilters}
          className="bg-yellow-500 text-white px-8 py-2 rounded-2xl"
        >
          Clear Filters
        </button>
        <button
          onClick={handleOpenPopup}
          className="bg-blue-500 text-white px-16 py-2 rounded-2xl"
        >
          Add
        </button>
        <UploadPopup isVisible={isPopupVisible} onClose={handleClosePopup} />
      </div>
      {/* Media list table */}
      <div className="p-10">
        <MediaTable media={media} />
      </div>

      <Pagination
        total={totalPages}
        current={currentPage}
        onPageChange={handlePageChange}
        maxWidth={5}
      />

      {/* <div className="absolute w-[1312px] h-[1217px] top-[124px] left-[64px]">
				<div className="h-[1260px]">
					<div className="relative w-[1312px] h-[1260px]">
						<div className="top-[209px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img.svg"
								/>
							</div>
						</div>
						<div className="top-[345px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="image.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="top-[481px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img-2.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="top-[617px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img-3.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="top-[753px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img-4.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="top-[889px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img-5.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="top-[1025px] absolute w-[1248px] h-[72px] left-[32px]">
							<div className="relative w-[1259px] h-[104px]">
								<div className="w-[1248px] h-[104px] bg-divider shadow-[inset_0px_-1px_0px_#e4e4e4] absolute top-0 left-0" />
								<div className="absolute w-[209px] h-[27px] top-[26px] left-[156px]">
									<div className="flex flex-col w-[224px] items-start justify-center gap-[9px] relative">
										<div className="relative w-[158px] mt-[-1.00px] font-title-16 font-[number:var(--title-16-font-weight)] text-textactive text-[length:var(--title-16-font-size)] tracking-[var(--title-16-letter-spacing)] leading-[var(--title-16-line-height)] [font-style:var(--title-16-font-style)]">
											Name
										</div>
									</div>
								</div>
								<div className="absolute w-[136px] top-[23px] left-[523px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Category Name
								</div>
								<div className="w-[136px] left-[723px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Tags Name
								</div>
								<div className="w-[136px] left-[923px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Location Name
								</div>
								<div className="w-[136px] left-[1123px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									30/09/2024
								</div>
								<img
									className="absolute w-[96px] h-[72px] top-0 left-[36px]"
									alt="Img"
									src="img-6.svg"
								/>
								<div className="w-[72px] left-[363px] absolute top-[23px] font-body-body-1-14 font-[number:var(--body-body-1-14-font-weight)] text-textactive text-[length:var(--body-body-1-14-font-size)] tracking-[var(--body-body-1-14-letter-spacing)] leading-[var(--body-body-1-14-line-height)] [font-style:var(--body-body-1-14-font-style)]">
									Image
								</div>
							</div>
						</div>
						<div className="absolute w-[1248px] h-[45px] top-[133px] left-[32px]">
							<div className="absolute w-[1248px] h-px top-[44px] left-0 bg-colordark-grey-neutralgreygrey-1" />
							<div className="left-[37px] absolute -top-px [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Media
							</div>
							<div className="left-[525px] absolute -top-px [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Category
							</div>
							<div className="absolute -top-px left-[364px] [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Type
							</div>
							<div className="left-[724px] absolute -top-px [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Tag
							</div>
							<div className="left-[927px] absolute -top-px [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Location
							</div>
							<div className="left-[1125px] absolute -top-px [font-family:'Inter-Medium',Helvetica] font-medium text-textdeactive-lightmode text-[13px] tracking-[0] leading-[18px] whitespace-nowrap">
								Date
							</div>
						</div>
						
					</div>
				</div>
			</div> */}
    </div>
  );
};

export default MediaList;
