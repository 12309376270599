// components/PrivateRoute.js
import React from "react";
import { Navigate } from "react-router-dom";
import { isAuthenticated } from "../utils/auth.js";

const PrivateRoute = ({ element: Component }) => {
	return isAuthenticated() ? <Component /> : <Navigate to="/sign-in" />;
};

export default PrivateRoute;
