import React, { useState, useEffect, useRef } from "react";
import { IoIosArrowDown } from "react-icons/io";

const Dropdown = ({
  name,
  options,
  selectedOptions = [],
  onSelect,
  multiSelect,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null); // Ref to the dropdown container

  const handleSelectionChange = (option) => {
    if (multiSelect) {
      if (selectedOptions.includes(option)) {
        onSelect(selectedOptions.filter((item) => item !== option)); // Remove if already selected
      } else {
        onSelect([...selectedOptions, option]); // Add new selection
      }
    } else {
      onSelect([option]); // Replace the current selection
      setIsOpen(false); // Close the dropdown for single select
    }
  };

  useEffect(() => {
    // Function to check if click is outside the dropdown
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if click is outside
      }
    };

    // Add event listener for clicks
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = () => setIsOpen(!isOpen); // Toggle the visibility of dropdown

  return (
    <div ref={dropdownRef} className="relative">
      <button
        onClick={toggleDropdown}
        className="border-none outline-none rounded-2xl p-4 w-60 bg-[#E4E4E4] text-[#808191] font-semibold text-sm"
      >
        <div className="flex justify-between items-center">
          <div className="flex flex-row gap-2">
          {multiSelect ? name : selectedOptions.join(", ") || name}
          </div>
          <IoIosArrowDown size={18} />
        </div>
      </button>
      {isOpen && (
        <div className="absolute top-full mt-2 w-60 bg-white border rounded-2xl shadow-lg z-10 h-64 overflow-scroll">
          {options.map((option, index) => (
            <label
              onClick={() => {
                if (!multiSelect) {
                  selectedOptions.includes(option);
                  handleSelectionChange(option);
                }
              }}
              key={index}
              className="flex items-center p-2 hover:bg-gray-100"
            >
              {multiSelect && (
                <input
                  type="checkbox"
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleSelectionChange(option)}
                  className="mr-2"
                />
              )}
              {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
